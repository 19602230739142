.ReportContainer {
  background-color: #eee;
  padding-bottom: 2vw;
  position: relative;
  @media screen and (max-width:1024px) {
    padding-top: 64px;
  }


  .Huanhuan {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9999;
    width: 20vw;
    height: 20vw;
    background: url('https://tc-cdn.123u.com/official-website/public/report_huanhuan.png') no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width:1024px) {
      opacity: 0.4;
    }
  }
  .Lele {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 20vw;
    height: 20vw;
    background: url('https://tc-cdn.123u.com/official-website/public/report_lele.png') no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width:1024px) {
      opacity: 0.4;
    }
  }

  .HeaderPlaceholder {
    @media screen and (min-width:1024px) {
      width: 100%;
      height: 60px;
      background-color: black;
    }
    @media screen and (max-width:1024px) {
      display: none;
    }
  }
  
  .Content {
    margin: 0 auto;
    background-color: white;
    padding: 2vw;
    border-radius: 0.3vw;
    @media screen and (min-width:1024px) {
      width: 60vw;
      margin-top: 2vw;
    }
    @media screen and (max-width:1024px) {
      width: 90vw;
    }
  }
}